.users-list-row {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
}

.users-dropdown-container {
  width: 260px;
  margin: 0px 2px;
  margin-top: 4px;
}

.criteria-button-container {
  button {
    width: 180px !important;
  }
}

.user-filter-container {
  display: flex;
  flex-flow: row;
  button {
    width: 200px !important;
    height: 40px;
    text-transform: capitalize !important;
  }
}

.company-filter-container {
  display: flex;
  flex-flow: row;
  button {
    width: 200px !important;
    height: 40px;
    text-transform: capitalize !important;
  }
}

@media only screen and (max-width: 776px) {
  .users-dropdown-container {
    width: 100%;
  }
}
