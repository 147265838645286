@import "../../../variables/variables.scss";

.stage-dropdown {
  display: flex;
  flex-flow: row;
  padding: 6px 6px;
  img {
    margin: 0px 6px;
    transition: all 300ms;
  }
  h3 {
    margin: 0px 0px 0px 12px;
    font-weight: 700;
  }
}
.stage-dropdown img:hover {
  cursor: pointer;
}

.old-stage {
  h3 {
    color: $old-stage-color;
  }
}

.current-stage-dropdown {
  transform: rotate(90deg);
}

.new-stage {
  h3 {
    color: $new-stage-color;
  }
}

.stage-opened-dropdown {
  transform: rotate(90deg);
}
