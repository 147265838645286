@import '../../../variables/variables.scss';

.icon-prize {
  height: 25px;
  text-align: left;
}

.special-award-component {
  display: flex;
  flex-flow: row;
}
