@import '../../../variables/variables.scss';

// Advance Filter

.advance-filter-section-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.cancel-filter-message {
  color: $clear-advance-close-filter;
  max-width: 200px;
  margin: 0px 0px 0px 12px;
  transition: all 300ms;
}
.cancel-filter-message:hover {
  cursor: pointer;
}

.clear-filter-message {
  color: $clear-advance-filter;
  max-width: 200px;
  margin: 0px 0px 0px 12px;
  transition: all 300ms;
}
.clear-filter-message:hover {
  cursor: pointer;
}

.advance-filter-container {
  display: flex;
  flex-flow: column;
  border: 1px solid $advance-filter-border-color;
  width: 100%;
  max-width: 700px;
  border-radius: 2px;
  position: relative;
  div:nth-child(1) {
    display: flex;
    // flex-flow: row;
  }
}
.advance-filter-container-input {
  border-right: 1px solid $advance-filter-border-color !important;
  border-radius: 0px !important;
  background-color: $advance-filter-input-bg-color;
}

.maginifier-container-img {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 60px;
  transition: all 300ms;
  img {
    height: 20px;
  }
}

.maginifier-container-img:hover {
  cursor: pointer;
}

.advance-filter-options {
  position: absolute;
  z-index: 10000;
  width: 100%;
  top: 36px;
  background-color: $advance-filter-bg-color;
  border: 1px solid $advance-filter-border-color;
}

.advance-filter-section {
  min-height: 60px;
  margin: 0px 12px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
}

.second-advance-filter-section {
  border-top: 1px solid $advance-filter-border-color;
}

.third-advance-filter-section {
  min-height: 50px;
  border-top: 1px solid $advance-filter-border-color;
  padding: 0px 6px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
}

.handle-filter-modal-button-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
  width: 150px;
}

.advance-date-container {
  min-height: 80px !important;
}

.advance-date-picker-container {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
}

#advance-date-picker {
  margin: 12px 0px 0px 0px;
  display: flex;
  flex-flow: column;
  input {
    // border: 0px;
    width: 165px;
  }
}

.advance-text-field {
  display: flex;
  flex-flow: row;
  align-items: center !important;
  justify-content: center;
}

.advance-separator-line {
  height: 2px;
  width: 20px;
  margin-top: 32px;
  background-color: $advance-separator-line-color;
}

#third-advance-filter-lable {
  width: 140px !important;
}

.advance-filter-lable {
  color: $admin-idea-lable-color;
  width: 140px;
  margin: 0px;
  font-weight: bold;
  text-align: left;
}

.advance-filter {
  width: 100%;
  border: none;
  padding: 0px 12px;
  font-weight: light;
  font-family: 'Roboto Condensed', sans-serif;
}

.advance-field-container {
  min-width: 280px;
  display: flex;
  flex-flow: row;
  align-items: center;
  // justify-content: space-between;
}

.advance-filter-field-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  h6 {
    margin: 0px 12px 0px 6px;
  }
}

.advance-search-button {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
  min-width: 150px;
  height: 35px;
  border-left: 1px solid $advance-filter-border-color;
  transition: all 300ms;
  h6 {
    margin-bottom: 0px;
  }
  img {
    width: 14px;
  }
}

.advance-search-button:hover {
  box-shadow: 0px 0px 4px $register-bg-color;
  cursor: pointer;
}

.export-found-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}

// Found Elements Component

.found-elements-component {
  color: $found-elements-color;
  max-width: 250px;
  text-align: left;
  margin-bottom: 0px;
}

// Export Component
.export-action-component {
  width: 60px;
  transition: all 300ms;
  img {
    margin-bottom: 2px;
  }
}

.export-action-component:hover {
  cursor: pointer;
}

// Pages per page
.pages-per-page-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 6px;
  h6 {
    width: 120px;
    margin: 6px 0px;
    text-align: left;
  }
  select {
    width: 80px;
    height: 30px;
    border-radius: 2px;
    border: 1px;
  }
}

@media only screen and (max-width: 600px) {
  .advance-filter-section {
    margin: 0px;
  }
  .advance-filter-field-container {
    margin-right: 2px;
    // display: block;

    input {
      height: 10px;
    }

    h6 {
      margin: 0px 0px 0px 3px;
      font-size: 11px;
    }
  }

  .advance-filter-lable {
    font-size: 12px;
    width: 12%;
    min-width: 55px;
  }

  .advance-filter-container {
    margin-bottom: 12px;
  }

  .advance-filter-options {
    padding-left: 5px;
    padding-right: 5px;
  }

  #advance-date-picker input {
    width: 85px;
    display: grid;
  }
}

@media only screen and (max-width: 400px) {
  .advance-filter-lable {
    font-size: 11px;
  }
}
