@import '../../../variables/variables.scss';

.responsive-sidebar {
  background-color: $sidebar-bg-color;
  max-width: 400px;
  width: 100%;
  box-shadow: 0px 0px 3px $idea-header-text-color;
  z-index: 20000;
  position: absolute;
  top: 0px;
  height: 100%;
  color: $login-dashboard-bg-color;
}

.sidebar-image {
  height: 30px;
}

#hamburger {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-evenly;
  height: 25px;
  width: 50px;
  margin: 0px 12px;
  div {
    height: 4px;
    width: 25px;
    background-color: $idea-header-text-color;
    border-radius: 5px;
  }
  transition: all 300ms;
}
#hamburge:hover {
  cursor: pointer;
}

.responsive-sidebar-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  padding: 6px 0px;
  div {
    width: 80%;
    img {
      width: 180px;
    }
  }
  h6 {
    font-family: sans-serif;
    font-weight: bold;
    color: $sidebar-bg-color;
    font-size: 30px;
    margin: 0px 0px 0px 0px;
  }
  h6:hover {
    cursor: pointer;
  }
  background-color: $login-dashboard-bg-color;
}

@media only screen and (min-width: 768px) {
  #hamburger {
    display: none;
  }

  .responsive-sidebar {
    display: none;
  }
}
