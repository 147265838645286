@import "../../../variables/variables.scss";

.preload-member-cv-name-container {
  margin: 12px 0px;
  span {
    font-weight: bold;
    margin-right: 12px;
  }
  h6 {
    text-align: left;
    font-size: 16px;
  }
}
