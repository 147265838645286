@import "../../../variables/variables.scss";

.dead-line-date {
  font-size: 24px !important;
  color: $card-limit-date;
  font-weight: bold !important;
}

body {
  background-color: #eee;
}

.mt-70 {
  margin-top: 70px;
}

.mb-70 {
  margin-bottom: 70px;
  background-color: $dasboard-bg-color;
}

.idea-detail h1 {
  font-weight: 300;
  font-size: 1.6em;
  text-align: left;
  text-transform: uppercase;
}

.idea-detail h3 {
  font-weight: 600;
  font-size: 0.8em;
  text-align: left;
  text-transform: uppercase;
  padding-top: 5%;
}

.idea-detail h4 {
  font-weight: 400;
  font-size: 1.4em;
  text-align: left;
  padding-top: 2px;
}

.card {
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border-width: 0;
  transition: all 0.2s;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(26, 54, 126, 0.125);
  border-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.participant-cv-dropzone {
  margin-top: 12px;
}

.vertical-timeline {
  width: 100%;
  position: relative;
  padding: 1.5rem 0 1rem;
}

.vertical-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 30px;
  height: 100%;
  width: 4px;
  background: #e9ecef;
  border-radius: 0.25rem;
}

.vertical-timeline-element {
  position: relative;
  margin: 0 0 1rem;
  margin-bottom: 5%;
}

.vertical-timeline-element .btn,
.vertical-timeline-element .btn-primary {
  color: grey;
  background: $dasboard-bg-color;
  border-color: white;
  font-size: 1.2em;
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
  visibility: visible;
  animation: cd-bounce-1 0.8s;
}

.btn-member img {
  margin: 10px;
}
.vertical-timeline-element-icon img {
  width: 45px;
  height: 45px;
}

.vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 10px;
}

.vertical-timeline-element-icon .badge-dot-xl {
  box-shadow: 0 0 0 5px #fff;
}

.badge-dot-xl {
  width: 18px;
  height: 18px;
  position: relative;
}

.badge:empty {
  display: none;
}

.badge-dot-xl::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 0.25rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -5px 0 0 -5px;
  background: $dasboard-bg-color;
}

.vertical-timeline-element-content {
  position: relative;
  margin-left: 100px;
}

.vertical-timeline-element-content .timeline-title {
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.4em;
  line-height: 2em;
  font-style: normal;
  font-weight: 500;
  text-align: left;
}

.vertical-timeline-element-content:after {
  content: "";
  display: table;
  clear: both;
}

// LeaderDropzone

.loading-files-spiner-container {
  h6 {
    margin-top: 6px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .vertical-timeline-element-content {
    position: relative;
    margin-left: 80px;
  }
}

.save-url {
  width: 100px;
  display: inline;
  margin: 10px;
}

.delete-img-link {
  display: inline;
  margin-left: 20px;
  cursor: pointer;
}
.save-url h4 {
  display: inline;
}

.save-button {
  width: 100px;
  text-align: center;
}

.services-div {
  width: 100%;
}

.services-div div {
  width: 25%;
  display: inline-block;
}

.services-div div img {
  cursor: pointer;
}

.info-services {
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
}

.left-align {
  text-align: left;
}
