@import "../../../variables/variables.scss";

.member-cards-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 12px 0px !important;
}

.member-card-border {
  padding: 6px 8px !important;
}

.member-card {
  border: 1px solid black;
  margin: 2px;
  padding: 8px 18px;
  width: 100%;
  height: auto;
  background: $idea-card-bg-color;
  border: 1px solid $idea-card-border-color;
  border-radius: 4px;
}

.member-card-header {
  color: $idea-card-header-text-color;
  justify-content: space-between;
  display: flex;
  
  img {
    margin-bottom: 6px;
    text-align: right;
    cursor: pointer;
  }

  h5{
    text-align: left;
    cursor: auto;
  }
}

.member-presentation-tag {
  color: #6d6d6d;
  text-align: left;
  margin-bottom: 2px;
  span {
    color: $registered-color;
  }
}

