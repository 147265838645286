.user-detail-round-container-component {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.round-name {
  border: 1px solid orange;
  background-color: orange;
  height: 140px;
  width: 140px;
  border-radius: 10000px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  h1 {
    color: white;
    font-size: 60px;
    margin: 0px;
    text-transform: uppercase;
  }
}

.user-detail-info-container {
  h6 {
    margin-bottom: 2px;
  }
  min-height: 180px;
  margin: 0px 24px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.user-detail-info {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.user-details-tabs-container {
  display: flex;
  flex-flow: row wrap;
}

.users-detail-tab-component {
  padding: 6px 0px;
  margin: 0px;
  h6 {
    padding: 0px 24px;
    font-size: 24px;
    margin: 0px;
  }
  div {
    height: 4px;
    width: 100%;
    // background-color: #30377b;
  }
}

.judge-change-tipe-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  min-width: 150px;
  ion-icon {
    color: blue;
    cursor: pointer;
    font-size: 20px !important;
    margin: 0px 12px !important;
  }
}

@media only screen and (max-width: 776px) {
  .user-details-tabs-container {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    h6 {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .user-detail-round-container-component {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
  }
}
