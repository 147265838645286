@import "../../../variables/variables.scss";

.dropzone-container {
  padding: 0px !important;
}

.dropzone-container > div:nth-child(1) {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  border: 2px dashed $drop_zone_border_color;
  background-color: $drop_zone_bg_color;
  border-radius: 8px;
  width: 100%;
  padding: 12px;
  transition: all 300ms;
}

.dropzone-container > div:nth-child(1):hover {
  cursor: pointer;
}

.dropzone-container > div:nth-child(1) {
  height: 150px;
}

.dropzone-container > div:nth-child(1) > div {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  img {
    width: 80px;
  }
}

.dropzone-container > div > div > h6 {
  margin-bottom: 0px;
  color: $input_text_color;
}

.load-file-button {
  border: 2px solid $login-button-bg-color;
}

.load-file-button h6 {
  color: $login-button-bg-color;
  margin-bottom: 0px;
}

@media only screen and (max-width: 767px) {
  .dropzone-container > div:nth-child(1) {
    min-height: 240px;
  }
}
