/* styles.css */
.custom-modal .modal-dialog {
    max-width: 90%;
    height: 90%;
    margin: 30px auto;
  }
  
  .custom-modal .modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .custom-modal .modal-body {
    flex: 1;
    overflow: hidden; /* Esto evita el scroll fuera de la modal */
  }
  
  .custom-modal .modal-footer {
    background-color: #f8f9fa;
  }