#special-mention-container {
  .gray-data-label {
    width: 300px;
  }
}

.mention-info-component-container {
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 24px;
}

.mention-info-component {
  width: auto;
  margin: 0px 24px 24px 0px;
  h6 {
    margin: 0px;
  }
}

#status-special-mention-input {
  width: 100%;
  .gray-data-label {
    width: 400px !important;
  }
}
