@import "../../../variables/variables.scss";

.footer {
  padding: 12px 24px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  background-color: $footer_bg_color;
  div:nth-child(3) {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
  }
  h5 {
    font-weight: 700;
    color: $need_help_color;
    font-size: 1.2em;
  }
  h6 {
    color: $contact_color !important;
    span {
      color: $url_color;
      text-decoration: underline;
    }
  }
}

.social-media-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  img {
    width: 35px;
    margin: 0px 12px;
  }
}

.footer-copy {
  font-family: $font_footer;
  color: $footer_copy_color;
  font-weight: normal;
}

@media only screen and (max-width: 900px) {
  .footer {
    padding: 24px 0px 12px 0px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    div:nth-child(3) {
      margin: 12px 0px;
      display: flex;
      flex-flow: column;
      align-items: center;
    }
  }

  .social-media-container {
    margin: 6px 0px;
  }
}
