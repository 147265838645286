@import '../../../variables/variables.scss';

.menu-container {
  background-color: $sidebar-bg-color;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
}

/* Sidebar del menu */

.menu-sidebar {
  width: 280px;
}

.menu-logo {
  /* width: 180px; */
  margin-top: 18px;
  margin-bottom: 50px;
}

.menu-option {
  height: 60px;
  color: $sidebar-text-color;
  text-align: left;
  display: flex;
  flex-flow: row;
  align-items: center;
  transition: all 300ms;
}

.menu-option:hover {
  background-color: $sidebar-selected-color;
  cursor: pointer;
}

.menu-option img {
  width: 25px;
  margin: 0px 18px;
}

.menu-option h6 {
  font-weight: 700;
  font-size: 16px;
  margin: 0px;
}

.menu-dashboard {
  background-color: $dasboard-bg-color;
  padding-top: 24px;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

/* Menu Header */

.menu-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px 20px 30px;
  margin-bottom: 36px;
}

.menu-header h1 {
  margin: 0px;
  text-align: left;
}

.menu-header > div {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
  color: $text-color;
  z-index: 3;
}

.menu-header div h6 {
  margin: 0px;
}

.menu-header div img {
  margin-left: 12px;
}

.menu-header-bar {
  height: 20px;
  width: 2px;
  background-color: $text-color;
  margin: 0px 10px;
}

/* Register */

.register-img-container {
  position: relative;
  margin-bottom: 24px;
  background-color: $register-bg-color;
}

.register-img-container h6 {
  font-weight: 700;
  color: $register-text-color;
  position: absolute;
  bottom: 12px;
  left: 12px;
  margin: 0px;
  font-size: 25px;
}

.register-img-container img {
  width: 100%;
  opacity: 0.9;
}

.register-input-container {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  text-align: left;
}

.input-eye-container {
  width: 100%;
  max-width: 400px;
  position: relative;
}

.input-eye-container i {
  position: absolute;
  right: 8%;
  top: 23%;
}

.input-eye-container i:hover {
  cursor: pointer;
}

.call-to-action-link {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.call-to-action-link h6 {
  margin: 0px 12px;
  text-align: center;
  outline: none;
}

.save-button-contanier {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  /* width: 100%; */
}

/* Password Change */

/* Listado de Usuarios */

.userlist-search-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.userlist-search-container > div {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.userlist-search-container input {
  margin-right: 12px;
}

.call-to-action-back {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.empty-lists {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  color: $menu-bg-color;
  font-weight: 950;
  font-size: 25px;
  margin-top: 10%;
  font-family: $font;
}

#create-new-user-button {
  margin-right: 6px;
}

#save-button {
  margin-top: 20px;
}

/* Update Password */
.font-eye {
  border: none;
  color: $menu-bg-color;
  background: none;
}

// Home component

.dashboard-title-container {
  margin: 0px 0px 12px 0px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: left;
    color: $login-button-bg-color;
  }
}

.dashboard-admin-title-container {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-between;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: left;
    color: $login-button-bg-color;
  }
  h4 {
    margin: 6px 20px;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: bold;
  }
}

.create-idea-button {
  background: $create-idea-button-color;
  box-shadow: 0px 2px 2px $create-idea-shadow-color;
  border-radius: 4px;
  color: $table-heder-text-color;
  padding: 6px 12px;
}

.create-idea-button:disabled {
  background: rgb(122, 122, 122);
  cursor: not-allowed;
}

.card {
  background: $card-bg-color;
  border: 1px solid $card-shadow-color;
  box-sizing: border-box;
  box-shadow: 0px 0px 7px $card-box-shadow-color;
  margin: 12px 0px;
}

.card-title {
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  text-transform: uppercase;
  text-align: left;
  color: $card-title-text-color;
  margin: 12px;
}

.header-logo {
  width: 130px;
  height: 50px;
}

@media only screen and (max-width: 767px) {
  .menu-sidebar {
    display: none;
  }
  .header-logo {
    display: none;
  }
  .menu-container {
    background-color: $login-dashboard-bg-color;
  }
}

@media only screen and (max-width: 450px) {
  .dashboard-title-container {
    align-items: center;
    justify-content: center;
  }
}
