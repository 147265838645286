.result-bar-component {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  h5 {
    margin: 0px;
    width: 100px;
  }
  h6 {
    margin: 0px;
    width: 300px;
    text-align: right;
  }
}

.result-container-color-bar {
  height: 30px;
  width: 500px;
  background-color: #f2f2f2;
  margin: 6px 24px;
  border-radius: 4px;
  position: relative;
}

.result-color-bar {
  position: absolute;
  height: 30px;
  background-color: #37bd45;
  border-radius: 4px;
}

.evaluation-bar-results-container {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.judge-evaluation-select-input {
  select {
    width: 240px !important;
    font-size: 18px;
  }
}

@media only screen and (max-width: 900px) {
  .evaluation-bar-results-container {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    overflow-x: scroll;
    min-width: 500px;
    margin: 12px 0px;
  }
  .result-container-color-bar {
    width: 60%;
  }
}
