@import "../../../variables/variables.scss";

#create-account-message {
  margin-bottom: 24px;
}

.terms-and-conditions-button-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
  input {
    margin: 0px 8px 0px 0px;
  }
  h6 {
    margin: 0px;
  }
  a {
    color: $url_color;
    text-decoration: underline;
  }
}

.create-account-container {
  display: flex;
  flex-flow: row;
  margin: 0px !important;
  min-height: 80vh;
  h6,
  h1 {
    text-align: center;
  }
  background: $login-dashboard-bg-color;

  img {
  }

  #account-sidebar {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  button {
    display: inline;
  }

  .no-padding {
    padding: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .side-img-disappear {
    display: none !important;
  }

  .terms-and-conditions-button-container, #register-email-message, #create-account-message, .create-account-container h6, .create-account-container h1{
    text-align: left;
    padding-top: 5px;
  }
}
