@import "../../../variables/variables.scss";
.home-container {
  h6 {
    text-align: left;
  }
}

.left-align-dashboard{
  text-align: left;
}

.icon-dashboard{
  width: 30px;
}
