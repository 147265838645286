@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400&display=swap');
body {
  color: #626060 !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: 'Roboto Condensed', sans-serif;
}

a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: none !important;
}

.Ts-op {
  opacity: 0.9;
}

/* Hide progressbar */
.Toastify__progress-bar {
  height: 0px !important;
}

/* Remove arrows from number imputs */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

textarea:focus,
input:focus {
  outline: none;
}

#root,
.App,
.content-page {
  min-height: 100%;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100% !important;
  }
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}
