@import "../../../variables/variables.scss";

.form-error-ok {
  height: 20px;
  padding-top: 2px;
}

.form-error-alert {
  margin-bottom: 18px;
  height: 25px;
  padding-top: 2px;
  padding-left: 0px;
  color: $error-alert-color;
  font-size: 14px;
  text-align: left;
  margin-bottom: 18px;
}
