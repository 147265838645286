@import '../../../variables/variables.scss';

.award-cards-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}

.checkbox-img {
  height: 12px;
  margin: 0px 6px 6px 0px;
  cursor: pointer;
}

.award-card {
  margin: 2px;
  padding: 8px 0px;
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  justify-content: flex-start;

  h5 {
    text-align: left;
    display: inline;
  }

  input {
    margin-right: 10px;
    text-align: left;
  }

  p {
    margin-left: 25px;
    margin-bottom: 0px;
  }
}
