.judges-list-container {
  padding: 0px;
  margin: 24px 0px;
  max-height: 200px;
  overflow-y: auto;
  h6 {
    margin: 0px;
    padding: 12px 6px;
    cursor: pointer;
    transition: all 300ms;
  }
  h6:hover {
    margin: 0px;
    padding: 12px 8px;
  }

  // h6:nth-child(odd) {
  //   background-color: #f0f0f0;
  // }
}

.judge-idea-info-margin-container {
  margin: 24px 0px !important;
}

.judge-idea-info-margin {
  margin-top: 12px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  img {
    cursor: pointer;
  }
}

.judge-modal-buttons-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.search-judge-input {
  .gray-data-label {
    max-width: 500px !important;
  }
}

#judge-name-id {
  input {
    width: 50%;
  }
}
