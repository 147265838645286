@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200&display=swap");
// Concuro idea Colors
$text-color: #30377b;
$sidebar-bg-color: #53627e;
$sidebar-selected-color: rgba(62, 66, 102, 1);
$input_label_color: rgba(154, 154, 154, 1);
$data-type-label-color: #b0b0b0;
$wizard-unselect-color: #b5b5b5;
$contest-input-container: #ababab;
$contest-input-bg-color: #e7e7e7;
$contest-input-text-color: #412c2c;
$contest-select-text-color: rgba(0, 0, 0, 1);

// Home
$img_bg_color: rgba(0, 0, 0, 0.8);
$search_button_border_color: #cbcbcb;

// Login
$login_bg_color: #000;
$login-dashboard-bg-color: #ffff;
$remember_password_color: rgba(135, 135, 135, 0.8);

// Input and Select
$input_border_color: #cbcbcb;
$input_text_color: rgba(58, 63, 65, 0.7);
$place_holder_text_color: #bebebe;
$blocked_input_bg_color: rgba(0, 0, 0, 0.05);
$blocked_input_text_color: rgba(0, 0, 0, 0.4);

// Header
$header_bg_color: #0098da;
$header-text-color: #ffffff;
$header-welcome-border-color: #c7c3c3;
// Card
$card-bg-color: #ffffff;
$card-shadow-color: rgba(211, 211, 211, 0.88);
$card-box-shadow-color: rgba(0, 0, 0, 0.12);

// Contest Stage Component
$contest-current-stage-color: #91b7fd;
$contest-next-stage-color: #d1d1d1;

// Idea Card
$create-idea-button-color: #006bb4;
$create-idea-shadow-color: rgba(152, 162, 179, 0.15);
$idea-card-bg-color: #ffffff;
$idea-card-border-color: #e0e0e0;

$idea-card-header-text-color: #a1a1a1;

$card-title-text-color: #474747;
$card-limit-date-message: #787878;
$card-limit-date: #7e82a5;
$registered-color: #f5a700;
$participant-color: green;
$error-color: red;

//Stage Dropdown
$new-stage-color: #37bd45;
$old-stage-color: #858585;

// Advance Filter
$advance-filter-border-color: #a7a7a7;
$advance-filter-bg-color: #ffff;
$clear-advance-filter: #5883c3;
$clear-advance-close-filter: #a7a7a7;
$admin-idea-lable-color: #3f3f3f;
$advance-separator-line-color: #aaaaaa;
$advance-filter-input-bg-color: rgba(243, 243, 243, 0.5);

// Found Elements
$found-elements-color: #3f3f3f;

// Loading ideas mesasge
$loading-ideas-color: #7e7e7e;

// Admin idea Table
$admin-idea-table-acciones-color: #5883c3;
$admin-idea-table-header-color: #30377b;
$admin-idea-table-border-color: #cdcdcd;
$admin-idea-table-odd-bg-color: #f3f3f3;
$admin-idea-table-odd-bg-even: #ffffff;

$url-link: #1f51ff;
// Idea Table
$idea-border-color: #d3dae6;
$idea-header-text-color: #000000;
$idea-table-bg-color: #ffffff;

// Dashboard
// $dasboard-bg-color: grey;
$dasboard-bg-color: #ffffff;

// Modal
$modal-bg-color: #ffffff;
$modal-header-color: #212529;
$modal-body-border-color: #dee2e6;
$modal-container-bg-color: rgba(103, 128, 159, 0.5);
$modal-accept-button-bg-color: #007bff;
$modal-cancel-button-bg-color: #6c757d;

// Tables
$table_header_bg_color: #6e849e;
$odd_bg_color: #eaeaea;
$even_bg_color: #f5f5f5;
$table_font_color: #3a3f41;
$table_blocked_text_color: #878787;
$table-heder-text-color: #ffffff;
$table_td_boder_color: #bebebe;
$font: "Roboto Condensed", sans-serif;
$font_footer: "Manrope", sans-serif;
$need_help_color: #5f5f5f;
$footer_copy_color: #b4b4b4;
$contact_color: #626060;
$url_color: #0038ff;
$pagination_a_color: #47ccde;

// Error alertl
$error-alert-color: #ee3232;

//Menu
$menu-bg-color: #0098da;
$menu_shadow_color: #0174ac;
$menu_text_color: #ffffff;
$sidebar-text-color: #cbcbcb;

$menu_hover_option_color: #0076b9;

// Register
$register-bg-color: #000000;
$register-text-color: #ffffff;

// Usuarios
$call_to_action_text_color: #18a0fb;

// Login Button
$login-button-bg-color: #30377b;
$login_button_bg_hover_color: rgba(48, 55, 123, 0.8);
$login_button_text_color: #fff;

// Blocked Button
$blocked_button_bg_color: rgba(48, 55, 123, 0.3);

// Edit Button
$edit_button_bg_olor: #59ba74;
$edit_button_text_color: #fff;

//Date Picker
$date_picker_border: #cbcbcb;

// Dropzone
$drop_zone_border_color: #eeeeee;
$drop_zone_bg_color: rgba(237, 237, 237, 0.5);

// Accepted Files
$accepted_files_text_color: #3a3f41;

// Load Spinner
$load_message_text_color: #7f7f7f;

// Alert
$alert_color: #ff0000;

// Check
$check_color: #74c277;

// Preferences
$open_stage_button_color: #017d73;
$close_stage_button_color: #bd271e;
$modal_delete_button: #ff0000;
$preferences_text_color: #000000;
$fase-ball_text_color: #ffffff;

$round_opened: #6dccb1;
$round_closed: #d3dae6;

$stage_date_limit_text: #e7e7e7;
$edit_stage_button_text_color: #0038ff;

$fase_ball: #006bb4;

// Dashboard Admin
$lightbulb-FA-color: #ffc90e;
$chalkboard-FA-color: #183153;
$admin-new-dashboard-container-color: #000000;

// $edit-stage-button-gage

// Footer
$footer_bg_color: rgba(255, 255, 255, 1);
