@import '../../../variables/variables.scss';

.action-button {
  width: 250px;
}

.confirm-wizard-button {
  border: 1px solid $header-text-color;
  background-color: $text-color;
  color: $header-text-color;
  transition: all 300ms;
}

.return-wizard-button {
  background-color: $header-text-color;
  border: 1px solid $text-color;
  color: $text-color;
  transition: all 300ms;
  h6 {
    margin-left: 12px;
  }
}
.return-wizard-button:hover,
.confirm-wizard-button:hover {
  opacity: 0.85;
  cursor: pointer;
}
.next-wizard-button {
  border: 1px solid $header-text-color;
  background-color: $text-color;
  color: $header-text-color;
  transition: all 300ms;
  h6 {
    margin-left: 12px;
  }
}

.next-wizard-button:hover {
  opacity: 0.85;
  cursor: pointer;
}

.wizard-button {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 14px 24px;
  border-radius: 5px;
  min-width: 170px;
  h6 {
    margin-bottom: 0px;
  }
}

#create-account-button {
  margin-top: 12px;
}
#consult-bond-by-number-button {
  margin-top: 24px;
}

#save-audit-button {
  width: 100%;
  margin-top: 12px;
}

.delete-button {
  background-color: $alert_color;
  color: $edit_button_text_color;
  width: 180px;
}

.delete-button:hover {
  cursor: pointer;
}

.cancel-button {
  border: 2px solid $login_button_bg_hover_color;
  width: 180px;
}

.cancel-button:hover {
  cursor: pointer;
}

.evaluation-status-button {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  padding: 4px 2px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
}

@media only screen and (max-width: 500px) {
  .wizard-button {
    min-width: 0px;
  }
}
