.terms-and-conditions-container {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin: 24px 0px;

  h6 {
    color: blue;
    cursor: pointer;
    margin: 2px;
  }
}

.image-management-component {
  margin: 48px 0px !important;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  h2 {
    color: #888888;
  }
}

.display-loaded-image-Logo {
  width: 250px;
  height: 100px;
  margin-bottom: 12px;
}

.display-loaded-image-Background {
  width: 550px;
  height: 700px;
  margin-bottom: 12px;
}
