.special-awards-container {
  display: flex;
  flex-flow: column;
}

.special-award-element {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin: 12px 6px;
}

.special-award-text {
  max-width: 800px;
  width: 100%;
}

.special-awards-actions-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 200px;
  justify-content: space-between;
  ion-icon {
    font-size: 24px !important;
    transition: all 300ms;
  }
  ion-icon:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}
