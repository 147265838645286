.contest-list-row {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.criteria-dropdown-container {
  width: 300px;
  margin: 0px 2px;
  margin-top: 4px;
}

.criteria-button-container {
  button {
    width: 180px !important;
  }
}

.loading-files-spinner-container {
  margin-top: 100px !important;
  margin-left: 100px !important;
  position: relative; /* Asegura que el contenedor padre use relative para que el spinner pueda ser absoluto dentro de él */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-files-spinner {
  top: 50%; /* Centra verticalmente */
  left: 50%; /* Centra horizontalmente */
  transform: translate(-50%, -50%); /* Ajusta el centrado */
  z-index: 10000;
}

.loading-files-spinner-container h6 {
  margin-top: 6px;
  font-size: 16px;
}
