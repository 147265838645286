@import '../../../variables/variables.scss';

#startup-input {
  // background-color: blue;
  align-items: flex-start;
  justify-content: flex-start;
  textarea {
    padding: 6px 12px;
    min-height: 100px;
    width: 100%;
    border: 2px solid $contest-input-container;
    border-radius: 3px;
    height: 40px;
    font-weight: light;
    font-family: 'Roboto Condensed', sans-serif;
  }
}
// Material datePicker

.MuiDialog-paperWidthSm {
  max-width: 320px !important;
}

.MuiInput-underline:before {
  border-bottom: 0px !important;
}

// .contest-input-flex {
//   padding-right: 0px !important;
// }

.error-contest-input {
  border: 2px solid red;
  padding: 2px;
  border-radius: 3px;
}

.contest-input-main-container {
  // width: 100% !important;
  padding-right: 0px !important;
  margin: 0px 6px 0px 0px;
  display: flex;
  flex-flow: column;
}

.helper-counter span {
  margin-left: 85px !important;
}

// Gray lable on Input

.gray-data-label {
  width: 50%;
  background-color: $contest-input-bg-color;
  min-height: 40px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 3px;
  h6 {
    text-align: right;
    font-weight: bold;
    margin: 0px 12px;
    color: $contest-input-text-color;
  }
}

.contest-input-container {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  input,
  select {
    width: 100%;
    border: 2px solid $contest-input-container;
    padding: 0px 12px;
    border-radius: 3px;
    height: 40px;
    font-weight: light;
    font-family: 'Roboto Condensed', sans-serif;
  }
  select {
    color: $contest-select-text-color;
    font-weight: 400;
  }
}

// ContestGenderInput

#cedula-select-container {
  div {
    display: flex;
    background-color: $login-dashboard-bg-color;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    h6 {
      font-weight: 400;
    }
  }
}

#gender-select-container {
  margin-top: 8px;
}
.gender-select-container {
  display: flex;
  width: 100%;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 0px 24px;
  h6 {
    margin: 0px 12px;
  }
}

.questions-select-container {
  width: 50%;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin: 10px;
  h6 {
    margin: 0px 12px;
  }
}

.radio-input {
  height: 18px !important;
  width: 18px !important;
}

// Date Picker

.birth-date-picker-container {
  border-radius: 3px;
  width: 100%;
  > div:nth-child(1) {
    width: 100%;
  }
}

// ConfirmInput
.confirm-data {
  min-height: 40px;
  width: 100%;
  text-align: left;
  h6 {
    margin: 0px 12px;
    margin-top: 9px;
  }
}

.desctiption-confirm-data {
  max-height: 250px;
  overflow: auto;
}

.confirm-input-container {
  margin: 6px 0px;
}

// EmploymentSituation

.employment-select-container {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 6px 18px;
  margin-bottom: 24px;
  div {
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    margin: 4px 0px;
    h6 {
      margin: 0px 12px;
    }
  }
}
.helper-text {
  margin-top: -20px;
  margin-bottom: 15px;
  text-align: right;
  justify-content: right;
  font-size: 0.8rem;
}

.contest-checks-container {
  width: 100%;
  height: 40px;
  display: flex;
  flex-flow: row wrap;
  align-items: center; // justify-content: space-evenly;
  > div {
    width: 120px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 12px;
    h6 {
      margin: 0px;
    }
    input {
      height: 14px;
      width: 14px;
      margin: 0px 6px;
    }
  }
}

#radio-selected-container {
  justify-content: flex-start;
}

.evaluations-search-pt1-element {
  width: 70%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
}
.evaluations-search-pt2-element {
  width: 30%;
  padding: 0px 12px;
}

@media only screen and (max-width: 1154px) {
  .evaluations-search-pt1-element {
    width: 100%;
  }
  .evaluations-search-pt2-element {
    width: 100%;
    margin: 12px 0px 0px 0px;
  }
}

@media only screen and (max-width: 768px) {
  .gray-data-label {
    width: 100%;
    align-items: flex-start;
    justify-content: left;
    justify-content: center;
    h6 {
      text-align: left;
    }
  }
}
