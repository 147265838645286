@import '../../../variables/variables.scss';

.idea-cards-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 12px 0px !important;
}

.idea-card-border {
  padding: 6px 8px !important;
}

.idea-card {
  border: 1px solid black;
  margin: 2px;
  padding: 8px 18px;
  width: 100%;
  height: 270px;
  background: $idea-card-bg-color;
  border: 1px solid $idea-card-border-color;
  border-radius: 4px;
}

.idea-card-header {
  color: $idea-card-header-text-color;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  img {
    margin-bottom: 6px;
  }
}

.idea-presentation-tag {
  color: #6d6d6d;
  text-align: left;
  margin-bottom: 2px;
}

.idea-title {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  margin: 12px 0px;
  h6 {
    text-transform: uppercase;
    color: $need_help_color;
    text-align: left;
  }
}

.card-limit-date-message {
  color: $card-limit-date-message;
  margin-bottom: 0px;
  font-size: 12px;
}

.card-limit-date {
  color: $card-limit-date;
}

.info-message-negative {
  color: $error-color;
}

.info-message-positive {
  color: $participant-color;
}

.registered-color {
  color: $registered-color;
}

.participant-color {
  color: $participant-color;
}

// Contest Stage Component

.contest-stage-component {
  margin: 12px;
}

.contest-stage-component-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.completed-stage-true {
  background-color: $contest-current-stage-color;
  border: 1px solid $contest-current-stage-color;
}

.completed-stage-false {
  background-color: $card-bg-color;
  border: 1px solid $contest-next-stage-color;
}

// Contest Ball
.contest-stage-ball {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 1000px;
  z-index: 2;
  h6 {
    margin-bottom: 4px;
  }
}

// Progress bar

.contest-stage-progress-bar {
  display: flex;
  height: 10px;
  padding: 0px 25px;
  width: 98%;
  position: absolute;
  border: 1px solid $contest-next-stage-color;
  left: 2;
}

.filled-stage-progfress-bar {
  height: 100%;
  max-width: 100%;
  background-color: $contest-current-stage-color;
  border-radius: 50px;
}

.filled-stage-container {
  height: 100%;
  width: 33.33%;
}

// Limid date Component
.limit-date-component {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 25%;
  height: 70px;

  h6 {
    font-size: 12px;
    margin: 0px;
  }
  h5 {
    font-size: 16px;
    margin: 0px;
  }
}

.judge-card-conatiner {
  height: 150px;
  padding: 24px 0px 0px 0px;
  margin: 12px !important;
  width: 22%;
  h2 {
    font-size: 24px;
    margin-bottom: 12px;
  }
  h3 {
    font-size: 50px;
    margin-bottom: 0px;
  }
}

.idea-list-card-conatiner {
  width: 48% !important;
  margin: 4px !important;
  padding-bottom: 12px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  h1 {
    font-size: 20px;
    color: black;
  }
  span {
    height: 350px;
    width: 100%;
    max-height: 350px;
    overflow-y: auto;
  }
}

.idea-status-ball {
  width: 12px;
  height: 12px;
  border-radius: 1000px;
}

.idea-list-status-element {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-left: 12px;
  h6 {
    margin: 0px 12px;
  }
}

.new-admin-dashboard-card-conatiner {
  padding: 24px 0px 0px 0px;
  margin: 12px !important;
  width: 30%;
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 50px;
    margin-bottom: 0px;
  }
}

.data-single-container-component {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.data-container-percentage-component {
  display: flex;
  flex-flow: column !important;
  align-items: center !important;
  justify-content: center !important;
}

.winner-ideas-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.winner-ideas-container {
  h1 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 30px;
    color: #00ba88;
    margin-bottom: 12px;
  }
}
.winner-ideas-list-container {
  h2 {
    font-size: 24px !important;
  }
}

.data-container-component,
.data-single-container-component,
.winner-ideas-container {
  border: 1px solid #ededed;
  border-radius: 8px;
  padding: 12px 6px;
  margin: 12px 24px;
}

.data-container-component {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.please-alignthis {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.data-container-component,
.data-container-component-single,
.data-single-container-component,
.winner-ideas-container {
  border: 1px solid #ededed;
  border-radius: 8px;
  text-align: left;
  h3 {
    margin: 0px 12px;
    font-size: 20px;
    color: #bec0cc;
  }
  h2 {
    margin: 6px 12px;
    font-size: 42px;
    font-weight: bold;
  }
}

.data-container-component-single {
  padding: 12px 24px 0px 24px !important;
}

.dashboard-bar-container {
  height: 46px;
  width: 96%;
  margin: 12px 2%;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-flow: row;
}

.dashboard-bar-color-first {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.dashboard-bar-color-last {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.dashboard-bar-color {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: black;
  font-weight: bold;
}

.new-dashboard-legend-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: flex-end;
}

.new-dashboard-legend-content {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 0px 12px;
  div {
    height: 16px;
    width: 16px;
    margin: 8px;
    border-radius: 10000px;
  }
}

.new-admin-dashboard-data-component-container {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-evenly;
}

.circular-progress-bar-container {
  width: 50%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 12px 0px;
  div {
    width: 100px;
  }
  h6 {
    margin-top: 2px;
    font-size: 20px;
    color: #00ba88;
  }
}

.progress-component-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 12px;
}

.progress-component-container-ammount-container {
  width: 50%;
  h2 {
    margin: 6px 12px;
    font-size: 42px;
    font-weight: bold;
  }
  h4 {
    margin: 0px 12px;
    font-size: 20px;
    color: #bec0cc;
  }
}

@media only screen and (max-width: 768px) {
  .limit-date-component {
    width: auto;
    height: 90px;

    h6 {
      font-size: 14px;
    }
    h5 {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .judge-card-conatiner {
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .judge-card-conatiner {
    width: 100%;
  }

  .new-admin-dashboard-card-conatiner {
    width: 100%;
  }
}
