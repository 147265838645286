/* styles.css */
.custom-modal .modal-dialog {
    max-width: 700px;
    height: 90%;
    margin: 30px auto;
  }
  
  .custom-modal .modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .custom-modal .modal-body {
    flex: 1;
    overflow-y: auto;
    height: calc(100vh - 200px); /* 100vh menos 200px (puede incluir headers, footers, etc.) */
    padding: 20px;
  }
  
  .custom-modal .modal-footer {
    background-color: #f8f9fa;
  }