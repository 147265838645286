@import '../../../variables/variables.scss';

/* Dynamic tables */

#users-table {
  td:nth-child(1) {
    width: 20%;
  }

  td:nth-child(2),
  td:nth-child(3) {
    width: 20%;
  }

  td:nth-child(4) {
    width: 25%;
  }
  td:nth-child(5) {
    width: 15%;
  }
}

// Ideas

#ideas-table {
  th {
    padding: 0px 8px;
  }
  td {
    padding: 8px 10px;
  }
  td:nth-child(1) {
    width: 25%;
  }
  td:nth-child(2) {
    width: 15%;
  }
  td:nth-child(3),
  td:nth-child(5) {
    width: 8%;
  }
  td:nth-child(4) {
    width: 14%;
  }
  td:nth-child(6) {
    width: 4%;
  }
}

#admin-ideas-table {
  border: 1px solid $admin-idea-table-border-color;
  thead {
    height: 40px;
    tr:nth-child(1) {
      background-color: $admin-idea-table-header-color;
      th {
        color: $admin-idea-table-odd-bg-even;
      }
    }
  }

  th {
    padding: 0px 8px;
  }
  td {
    padding: 8px 10px;
  }
  td:nth-child(1),
  td:nth-child(6),
  td:nth-child(5) {
    width: 5%;
  }
  td:nth-child(2),
  td:nth-child(3) {
    width: 15%;
  }
  td:nth-child(4) {
    width: 10%;
  }
  td:nth-child(7) {
    width: 9%;
  }
  td:nth-child(8) {
    width: 4%;
  }
}

.loading-ideas-container {
  min-height: 250px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  h4 {
    margin-top: 12px;
    color: $loading-ideas-color;
  }
}

.processtable {
  max-width: 1200px;
}

#criteria-table {
  width: 100% !important;
  th:nth-child(1) {
    width: 25%;
  }
  th:nth-child(2),
  th:nth-child(3) {
    width: 12%;
  }

  border: 1px solid $admin-idea-table-border-color;
  thead {
    height: 40px;
    tr:nth-child(1) {
      background-color: $admin-idea-table-header-color;
      th {
        color: $admin-idea-table-odd-bg-even;
      }
    }
  }
  th {
    padding: 0px 8px;
  }
  td {
    padding: 8px 10px;
  }
  // thead th:nth-child(5) {
  //   background-color: white !important;
  //   border: none;
  // }
}

#evaluators-table {
  width: 100% !important;
  th:nth-child(1) {
    width: 20%;
  }
  th:nth-child(2),
  th:nth-child(3) {
    width: 20%;
  }

  border: 1px solid $admin-idea-table-border-color;
  thead {
    height: 40px;
    tr:nth-child(1) {
      background-color: $admin-idea-table-header-color;
      th {
        color: $admin-idea-table-odd-bg-even;
      }
    }
  }
  th {
    padding: 0px 8px;
  }
  td {
    padding: 8px 10px;
  }
  // thead th:nth-child(5) {
  //   background-color: white !important;
  //   border: none;
  // }
}

.ideas-tables-action-container {
  display: flex;
  flex-flow: row;
  ion-icon {
    font-size: 20px;
    margin: 0px 6px;
    cursor: pointer;
  }
}

#admin-ideas-table tr:nth-child(even) {
  background-color: $admin-idea-table-odd-bg-even;
}

#admin-ideas-table tr:nth-child(odd) {
  background-color: $admin-idea-table-odd-bg-color;
}

#admin-ideas-table {
  tr:nth-child(odd) {
    button {
      background-color: $admin-idea-table-odd-bg-color;
      border: 1px solid $admin-idea-table-odd-bg-color;
      color: $admin-idea-table-acciones-color;
    }
  }
  tr:nth-child(even) {
    button {
      background-color: $admin-idea-table-odd-bg-even;
      border: 1px solid $admin-idea-table-odd-bg-even;
      color: $admin-idea-table-acciones-color;
    }
  }
}

.ideas-table {
  text-align: left;
  font-family: $font;
  border-collapse: collapse;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 12px;
  background-color: $idea-table-bg-color;
}

.ideas-table thead th {
  color: $idea-header-text-color;
  padding: 12px 0px;
  border-bottom: 1px solid $idea-border-color;
}

.ideas-table td {
  padding: 12px 5px;
  text-align: left;
  border-bottom: 1px solid $idea-border-color;
}

.MuiPagination-ul {
  justify-content: center;
  margin: 24px 0px 12px 0px !important;
}

// StatusComponent
.status-component {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 8px;
  img {
    margin-right: 12px;
  }
  h6 {
    margin-bottom: 0px;
  }
}

// User

.users-table {
  text-align: center;
  font-family: $font;
  border-collapse: collapse;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 12px;
}

.users-table th:first-child {
  border-top-left-radius: 12px;
}

.users-table th:last-child {
  border-top-right-radius: 12px;
}

.users-table th {
  background-color: $table_header_bg_color;
  color: $table-heder-text-color;
  padding: 10px 12px;
  text-align: center;
}

.users-table td {
  padding: 8px 12px;
  text-align: left;
  border: 1px solid $table_td_boder_color;
}

.users-table td > h6,
.users-table th > h6 {
  color: $text-color;
  margin: 0px;
}

.users-table tr:nth-child(even) {
  background-color: $even_bg_color;
}

.users-table tr:nth-child(odd) {
  background-color: $odd_bg_color;
}

.edit-button {
  background-color: $edit_button_bg_olor;
  color: $edit_button_text_color;
  transition: all 300ms;
}

.edit-button:hover {
  opacity: 0.8;
  cursor: pointer;
}

// Pagination

.pagination {
  margin: 36px 0px;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: $table_header_bg_color;
  border-color: $table_header_bg_color;
  color: $table-heder-text-color !important;
}
.pagination > li > a {
  border: 1px solid $table_header_bg_color;
  padding: 5px 10px;
  outline: none;
  font-family: $font;
  color: $table_font_color;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: $table_header_bg_color;
  border-color: $table_header_bg_color;
  outline: none;
  color: $table_font_color;
}
.pagination > li > a,
.pagination > li > span {
  font-family: $font;
  color: $pagination_a_color;
  background-color: $even_bg_color;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

// Bonds Table
#bonds-table td:nth-child(5) h6 {
  text-align: right;
}

#bonds-table {
  td:nth-child(1),
  td:nth-child(2) {
    width: 15%;
    text-align: center;
  }
  td:nth-child(3) {
    width: 10%;
    text-align: center;
  }

  td:nth-child(4) {
    width: 2.5%;
    text-align: center;
  }

  // td:nth-child(5) {
  //   width: 11%;
  //   text-align: center;
  // }
  // td:nth-child(6) {
  //   width: 1%;
  //   text-align: center;
  // }
}

.table-action-button-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
}

.no-font-awesome-element {
  height: 26px;
  width: 28px;
}

// Comments table
.comments-table-container {
  width: 100%;
  min-height: 75vh;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: unset;
}

#comments-table {
  width: 99%;
  td:nth-child(1) {
    width: 15%;
    text-align: center;
  }
  td:nth-child(2) {
    width: 50%;
    text-align: left;
  }
  td:nth-child(3) {
    width: 5%;
    text-align: center;
  }
}

// Bonds Gov Table
#gov-bonds-table td:nth-child(6) h6 {
  text-align: right;
}

#gov-bonds-table {
  td:nth-child(1),
  td:nth-child(2) {
    width: 13%;
    text-align: center;
  }
  td:nth-child(3) {
    width: 13%;
  }
  td:nth-child(4) {
    width: 10%;
    text-align: center;
  }
  td:nth-child(5) {
    width: 5%;
    text-align: center;
  }
  td:nth-child(6) {
    width: 11%;
  }
  td:nth-child(7) {
    width: 1%;
    text-align: center;
  }
}

.img-button-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.img-button {
  background-color: $edit_button_bg_olor;
  border-radius: 6px;
  padding: 0px 28px;
  height: 40px;
  min-width: 40px;
  width: 60px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  transition: all 300ms;
}

.img-button:hover {
  cursor: pointer;
}

.img-button img {
  height: 20px;
}

.font-button {
  border: none;
  color: $table_header_bg_color;
  background: none;
}

// BondDataTable

.bond-data-table-container {
  display: flex;
  flex-flow: column;
  border: 1px solid $table_header_bg_color;
  border-radius: 10px;
  width: 100%;
}

.bond-data-table-container > div {
  display: flex;
  flex-flow: row;
}

.bond-data-table-container > div:nth-child(even) {
  border-top: 1px solid $table_header_bg_color;
  border-bottom: 1px solid $table_header_bg_color;
}

.bond-data-table-row {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  // width: 40%;
  padding: 6px 12px;
}

.table-row-60 {
  width: 60%;
}

.table-row-40 {
  width: 40%;
}

.table-row-30 {
  width: 30%;
}

.bond-data-table-row {
  h6:nth-child(1) {
    color: $text-color;
  }
  h6:nth-child(2) {
    color: $table_blocked_text_color;
  }
}

#general-data-title {
  margin-bottom: 8px;
}

.bond-number-container {
  display: flex;
  flex-flow: row;
}

.check-square {
  color: $check_color;
  margin-right: 12px;
  font-size: 18px;
}

.btn-delete {
  cursor: pointer;
  margin-left: 5px;
  color: $modal-accept-button-bg-color;
}

.btn-delete:hover {
  opacity: 0.8;
  cursor: pointer;
}

// Modal Component

.modal-component-container {
  background-color: $modal-container-bg-color;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.modal-component {
  background-color: $modal-bg-color;
  max-width: 500px;
  width: 100%;
  min-height: 200px;
  border-radius: 12px;
  margin: 0px 12px 120px 12px;
}

.modal-component-container-header {
  h6 {
    font-size: 24px;
    padding: 12px 20px;
    border-radius: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: $modal-header-color;
    text-align: left;
    background-color: $modal-bg-color;
  }
}
.modal-component-body {
  border-top: 1px solid $modal-body-border-color;
  padding: 12px 20px;
  border-bottom: 1px solid $modal-body-border-color;
  p {
    margin: 0px;
  }
}

.modal-component-footer {
  padding: 12px 20px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.modal-close-button {
  background-color: $modal-cancel-button-bg-color;
  border: 1px solid $modal-cancel-button-bg-color;
}

.modal-open-button {
  background-color: $modal-accept-button-bg-color;
  border: 1px solid $modal-accept-button-bg-color;
}

.modal-button {
  margin: 0px 6px;
  min-width: 100px;
  min-height: 36px;
  border-radius: 6px;
  color: $modal-bg-color;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  h6 {
    padding: 0px 12px;
    margin: 0px;
  }
  transition: all 300ms;
}

.modal-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.modal-button-container {
  display: flex;
  flex-flow: row;
}

.tables-container {
  overflow-x: auto;
  width: 100%;
}

.dropdown {
  position: static !important;
}

.pagination-container {
  width: 100%;
  min-width: 500px;
}

.distribution-table th {
  padding: 0px 8px;
  width: 25%;
}

.distribution-table td h6 {
  font-size: 18px;
}

.evaluation-status-component {
  padding: 6px 12px;
  border-radius: 4px;
  width: 150px;
  h6 {
    margin: 0px;
    font-weight: bold;
    text-align: center;
    color: white;
  }
}

.evaluation-status-bar-percentage-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  div {
    width: 100%;
  }
  h6 {
    margin: 0px 12px;
    width: 50px;
  }
}

.name-and-trophy-container {
  display: flex;
  flex-flow: row;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px; /* Ajusta este valor según el espacio que quieras ocupar */
  width: 100%;
}

/* styles.css */
.criteria-table-container {
  max-height: 80vh; /* Ajusta la altura según sea necesario */
  overflow-y: auto; /* Activa el scroll vertical */
}

.criteria-table {
  width: 100%;
  min-width: 600px; /* Asegura que la tabla tenga un ancho mínimo adecuado */
}
