@import "../../../variables/variables.scss";

// Wizard Component
.MuiPaper-root {
  width: 100%;
}

.MuiStepConnector-alternativeLabel {
  top: 16px !important;
}

.MuiStepConnector-root {
  height: 6px;
  background-color: $text-color;
}

@media only screen and (max-width: 768px) {
  .MuiStepConnector-root {
    display: none;
  }
}

.MuiSvgIcon-root {
  width: 40px !important;
  height: 40px !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
  font-size: 18px !important;
  font-weight: bold !important;
  font-weight: 500;
  color: $text-color !important;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  font-size: 18px !important;
  color: $wizard-unselect-color;
  font-family: "Roboto Condensed", sans-serif;
}

.basic-data-step-img {
  width: 100%;
}

.basic-data-step-img_short {
  width: 100%;
  display: none;
}

.call-to-action {
  color: $contact_color;
  font-weight: 500;
  font-size: 24px;
}

.data-type-label {
  color: $data-type-label-color;
  font-size: 18px;
  margin: 24px 0px 12px 0px;
}

.form-buttons-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px !important;
  margin-top: 24px;
}

@media only screen and (max-width: 425px) {
  .basic-data-step-img {
    width: 100%;
    display: none;
  }

  .basic-data-step-img_short {
    display: block;
  }
}
