@import '../../../variables//variables.scss';
.blocked-input {
  background-color: $blocked_input_bg_color;
  color: $blocked_input_text_color;
}

#register-email-message {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
}

.input-icon {
  padding-right: 60px;
}

.confirm-basic-data-display {
  display: flex;
  flex-flow: column;
  align-items: center;
}

p {
  text-align: left;
  color: black;
  font-family: 'Roboto Condensed', sans-serif;
}

.row-confirm p {
  margin-top: 24px;
}

.row-confirm {
  margin-bottom: 5px;
}

.questions-select-container h6 {
  text-align: right;
}

.input-question-idea {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .helper-counter span {
    display: none;
  }

  .questions-select-container h6 {
    margin-bottom: 10px;
    text-align: left;
    margin-left: 0px;
  }

  .input-question-idea h6 {
    margin-left: 10px;
  }
}
