pdf-viewer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
  }
  

  
  .pdf-viewer {
    width: 100%;
    height: 100vh;
    border: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  .download-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  
    &:hover {
      background-color: #0056b3;
    }
  }