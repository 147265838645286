@import '../../../variables/variables.scss';

.login-container {
  overflow: hidden;
  margin: 0px !important;
}

.login-dashboard {
  min-height: 80vh;
  padding: 20px 40px !important;
  background-color: $login-dashboard-bg-color;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.login-dashboard-logo {
  width: 250px;
  height: 100px;
}

.login-dashboard-background {
  // width: 250px;
  min-height: 700px;
}

#login-title {
  padding: 0px 20px;
}

.title {
  font-weight: 700;
  color: $text-color;
  font-size: 20px;
  text-align: left;
  margin: 24px 0px;
}

.login-line {
  width: 100%;
  height: 2px;
  background-color: $text-color;
  margin-bottom: 40px;
}

#login-button {
  width: 100%;
  max-width: 400px;
  text-transform: uppercase;
}

/* Inputs formularios */

.login-ident-content {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  h6 {
    font-size: 1em;
    text-align: left;
    margin-bottom: 12px;
  }
  h6:nth-child(2) {
    a {
      text-decoration: underline;
    }
  }
  h6:nth-child(4) {
    margin: 0px 0px 12px 0px;
  }
  h6:nth-child(5) {
    margin: 6px 0px 18px 0px;
  }

  h6:nth-child(8) {
    font-weight: bold;
    margin: 24px 0px 12px 0px;
  }
}

.input-title {
  text-align: left;
  font-family: $font;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  color: $input_label_color;
}

.input {
  border-radius: 5px;
  border: 2px solid $input_border_color;
  width: 100%;
  max-width: 400px;
  height: 40px;
  padding-left: 12px;
  padding-bottom: 0px;
  font-weight: 700;
  color: $input_text_color;
  font-family: 'Roboto Condensed', sans-serif;
}

select {
  border-radius: 5px;
  border: 2px solid $input_border_color;
  width: 100%;
  height: 40px;
  padding-left: 12px;
  padding-bottom: 0px;
  font-weight: 700;
  color: $input_text_color;
  font-family: 'Roboto Condensed', sans-serif;
}

::placeholder {
  color: $place_holder_text_color;
  font-size: 14px;
}

.login-copyright {
  color: $place_holder_text_color;
  font-size: 12px;
}

/* Recordar Contraseña */
.rememeber-password {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin: 12px 0px;
}

.rememeber-password div {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px gray solid;
  box-shadow: 2px $place_holder_text_color;
  margin-right: 12px;
}

.rememeber-password h6 {
  color: $remember_password_color;
  font-size: 1em;
  margin: 0px;
}

/* Olvide Contraseña */

.forgot-password {
  font-size: 1em;
  margin-top: 12px;
  text-decoration: underline;
}

/* Button */

.button {
  border-radius: 6px;
  padding: 0px 28px;
  height: 48px;
  min-width: 120px;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  transition: all 300ms;
  h6 {
    margin: 0px;
  }
}

.login-button:hover {
  cursor: pointer;
  border: 2px solid $login_button_bg_hover_color;
  background-color: $login_button_bg_hover_color;
}

.login-button {
  background-color: $login-button-bg-color;
  border: 2px solid $login-button-bg-color;
  color: $login_button_text_color;
}

.blocked-button {
  border: 2px solid !important;
  background-color: $blocked_button_bg_color;
}

.blocked-button:hover {
  cursor: context-menu $blocked_button_bg_color !important;
  background-color: $blocked_button_bg_color;
}

/* Slogan */

#login-slogan-container {
  height: 100vh;
}

.login-background-container {
  padding: 0px !important;
  img {
    width: 100%;
  }
}

#login-slogan-container h1 {
  width: 450px;
  bottom: 60px;
  left: 50px;
}

.login-form-container {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 100%;
  input {
    max-width: 400px;
  }
}

.slogan-container {
  width: auto;
  color: $menu_text_color;
  text-align: left;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.slogan-container h1 {
  position: absolute;
  font-size: 30px;
  font-weight: 700;
  z-index: 5;
}

.slogan-container img {
  position: absolute;
  top: -112px;
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .login-dashboard {
    padding: 20px !important;
  }

  .login-dashboard-logo {
    width: 150px;
  }

  .login-background-container {
    display: none;
  }

  .login-form-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
  }
}
