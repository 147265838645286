@import '../../../variables/variables.scss';

.header {
  background-color: $dasboard-bg-color;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 6px solid $text-color;
  svg {
    font-size: 25px;
    color: $text-color;
    margin-right: 24px;
  }
}

#header-dropdown {
  background-color: $dasboard-bg-color;
  border: $dasboard-bg-color;
}

#header-dropdown::after {
  color: $idea-header-text-color;
}

#header-welcome-component {
  border-left: 1px solid $header-welcome-border-color;
  min-width: 300px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 12px;
  div:nth-child(1) {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 80%;
    h5 {
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      color: $contact_color;
      margin-bottom: 6px;
    }
    h6 {
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
      color: $contact_color;
    }
  }
}

#header-welcome-option {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: auto;
}

.header div {
  color: $header-text-color;
  width: 150px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
}

.header div:hover {
  cursor: pointer;
}

.header div h6 {
  margin: 0px;
}

.header-logo {
  width: 130px;
  margin-left: 12px;
}

.header h5 {
  color: $idea-header-text-color;
  font-size: 1.25em;
}

.header h6 {
  color: $idea-header-text-color;
  font-size: 1em;
  text-transform: uppercase;
}

.image-logo-6-empty-container {
  width: 200px;
}

@media only screen and (max-width: 767px) {
  #header-welcome-component {
    min-width: 0px;
  }
  #header-welcome-component div:nth-child(1) {
    align-items: center;
    justify-content: center;
  }
}
