.reports-list-container {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.report-element-component {
  background-color: #f2f2f2;
  padding: 6px 12px;
  margin-top: 12px;
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  h4 {
    margin: 0px;
    min-width: 298px;
    text-align: left;
  }
}

.report-filter-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100% !important;
}

.report-filter-wrapper {
  margin: 0px 6px;
  min-width: 250px;
}

@media (min-width: 1000px) {
  .report-filter-wrapper {
    margin: 0px 6px;
    max-width: 250px;
  }
}
