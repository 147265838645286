@import "../../../variables/variables.scss";

// Wizard Component
.MuiPaper-root {
  width: 100%;
}

.display-inline{
  display: inline;
  padding-right: 10px;
}