@import '../../../variables/variables.scss';

.preferences-subtitle {
  text-align: left;
  font-size: 24px;
}

.preferences-subtitle-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.modal-delete-button {
  background-color: $modal_delete_button;
}

.preference-card-container {
  border: 1px solid $preferences_text_color;
  display: flex;
  flex-flow: row;
}

.preferences-component-footer {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: space-between;
}

.close-stage-button {
  border: 2px solid $close_stage_button_color;
  color: $close_stage_button_color;
  h6 {
    font-weight: 600;
  }
}

.open-stage-button {
  border: 2px solid $open_stage_button_color;
  color: $open_stage_button_color;
  h6 {
    font-weight: 600;
  }
}

// Fase Title

.fase-title-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  h4 {
    color: $preferences_text_color;
    margin: 0px 12px 0px 0px;
  }
}

.fase-ball {
  height: 36px;
  width: 36px;
  background-color: $fase_ball;
  border-radius: 1000px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin: 0px 12px 0px 0px;
  h6 {
    color: $fase-ball_text_color;
    margin: 0px;
  }
}

// Round Status

.round-status {
  padding: 2px 12px;
  border-radius: 4px;
  h6 {
    font-weight: 600;
    color: $preferences_text_color;
    margin: 0px;
  }
}

.round-opened {
  background-color: $round_opened;
}
.round-closed {
  background-color: $round_closed;
}

// EditButton

.stage-button {
  margin-left: 0px;
}

.edit-stage-button {
  padding: 0px 24px;
  margin-bottom: 12px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  h6 {
    font-size: 24px;
    color: $edit_stage_button_text_color;
    transition: all 300ms;
  }
  h6:hover {
    cursor: pointer;
  }
}

// Stage Card
.stage-card-component {
  margin-bottom: 36px;
}

.stage-card-body {
  padding: 24px;
  display: flex;
  flex-flow: column;
}

// StageDateLimitComponent

.stage-date-limit-container {
  margin: 6px 0px 24px 0px;
}

.stage-date-limit-component {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  h6 {
    margin: 0px;
  }
}
.stage-date-limit-component-text {
  background-color: $stage_date_limit_text;
  width: 220px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  border-radius: 6px;
  padding: 0px 12px;
  height: 40px;
  margin: 0px 12px 0px 0px;
  h5 {
    color: $preferences_text_color;
    margin: 0px;
    font-size: 16px;
    font-weight: bold;
    text-align: right;
  }
}

// StageHandlerComponent

.stage-handler-component {
  margin: 6px 0px 24px 0px;
  display: flex;
  flex-flow: row;
  align-items: center;
  h6 {
    margin: 0px 12px 0px 0px;
    color: $preferences_text_color;
    font-weight: bold;
    width: 220px;
    text-align: left;
  }
}

.switch-img {
  transition: all 300ms;
}

.switch-img:hover {
  cursor: pointer;
}

.contest-name-input-container {
  width: 400px;
  padding-top: 4px;
}

.contest-options-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 0px 24px;
  margin: 12px 0px 36px 0px;
  h6 {
    text-transform: capitalize;
    margin: 12px 6px;
    font-weight: bold;
    cursor: pointer;
    font-size: 20px;
    transition: all 300ms;
  }
  h6:hover {
    opacity: 0.8;
  }
}

.contest-list-container {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.contest-list-column {
  width: 400px;
}

.modality-type-title {
  text-align: center;
  font-weight: bold;
}

.disabledStage {
  background-color: #f6f6f6 !important;
  opacity: 0.4;
}

.disabled-stage {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
}

.create-contest-button-container {
  display: flex;
  flex-flow: row wrap;
  border: 1px solid black;
}

@media only screen and (max-width: 768px) {
  .contest-options-container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    h6 {
      margin: 4px 0px;
    }
  }
}

@media only screen and (max-width: 699px) {
  .create-contest-button-container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
  }
}
