.evaliation-state-container {
  border: 1px solid #b4b4b4;
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-radius: 1000px;
  div:nth-child(1) {
    border-top-left-radius: 1000px;
    border-bottom-left-radius: 1000px;
  }
  div:nth-child(2) {
    border-left: 1px solid #b4b4b4;
    border-right: 1px solid #b4b4b4;
  }
  div:nth-child(3) {
    border-top-right-radius: 1000px;
    border-bottom-right-radius: 1000px;
  }
}

.evaluation-stage-element {
  border: none;
  width: 33.33%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background-color: #e5e5e5;
  height: 100%;
  h6 {
    margin: 0px;
    font-size: 20px;
    color: #858585;
  }
}

.evaluation-stage-is-open {
  background-color: #3cc21b;
  h6 {
    color: white;
  }
}

.evaluation-filter-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  // padding: 0px 12px;
  overflow: hidden;
  .gray-data-label {
    max-width: 200px;
  }
  input {
    width: 240px;
  }
  button {
    width: 120px !important;
    height: 40px;
    text-transform: capitalize !important;
  }
}

.judge-evaluation-filters-container {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.judge-evaluation-end-date-message {
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .evaluation-filter-container {
    justify-content: space-between;
  }
}
