.no-files-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #808080; /* Color gris */
    text-align: center;
  }
  
  .no-files-message h2 {
    margin: 0;
  }